import { Typography, MenuItem } from "@material-ui/core";
import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Field, Form, Formik, useField } from "formik";
import { makeStyles, CircularProgress, Chip, OutlinedInput } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { object, string, number, array } from "yup";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "@material-ui/core";
import useApi from "hooks/useApi";
import AddonsList from "./common/AddonsList";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
const initialValues = {
    name: "",
    addon_code: null,
    price: 0,
};
const useStyle = makeStyles((theme) => {
    return {
        textCenter: {
            textAlign: "center",
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            height: 22,
            fontSize: 9,
            marginRight: 5,
            padding: 0,
        },
        primColor: {
            color: theme.palette.primary.main,
        },
        hr: {
            marginTop: 20,
            marginBottom: 20,
        },
        heading: {
            color: "#2C2C2C",
        },
        input: {
            padding: "0px",
        },
        inputLabel: {
            position: "absolute",
            left: 10,
            top: -10,
            paddingLeft: 2,
            paddingBottom: 3,
            backgroundColor: "#FFFFFF",
            paddingRight: 45,
            zIndex: 1000,
        },
        select: {
            margin: "13px 0px",
        },
    };
});

const CustomSelect = (props) => {
    const classes = useStyle();
    const [field] = useField({
        name: props.name,
        type: "select",
        value: props.value,
    });

    return (
        <FormControl fullWidth className={classes.select}>
            <InputLabel className={classes.inputLabel}>{props.label}</InputLabel>
            <Select {...props} {...field} onChange={props.onChange}>
                {props.children}
            </Select>
        </FormControl>
    );
};

const Addons = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [addonsItemPrice, addonsItemPriceIsLoading, getAddonsitemPrice] = useApi(
        React.useMemo(
            () => ({
                initialValues: [],
                module: "subscription",
                method: "getAllAddonsItemPrice",
                apiData: {
                    currency: "INR",
                },
            }),
            []
        )
    );
    const [subscribeUserResponse, subscribeUserIsLoading, subscribeUser] = useApi();
    console.log(addonsItemPrice);
    const classes = useStyle();
    const selectedAddons = props.selectedAddons;
    const setSelectedAddons = props.setSelectedAddons;
    const createdAddons = props.createdAddons;
    const setCreatedAddons = props.setCreatedAddons;

    const onCreateAddon = (value) => {
        const copy = [...createdAddons];
        console.log(value);
        copy.push(value);
        console.log(copy);
        props.setUserData((prevState) => ({
            ...prevState,
            createdAddons: copy,
        }));
        setCreatedAddons(copy);
        console.log(createdAddons);
    };
    console.log(selectedAddons);
    const onAddonChange = (e) => {
        setSelectedAddons(e.target.value);
        props.setUserData((prevState) => ({
            ...prevState,
            selectedAddons: e.target.value,
        }));
    };
    const subscribe = async () => {
        try {
            await subscribeUser({
                module: "auth",
                method: "adminRegister",
                apiData: {
                    data: props.userData,
                },
                throwError: true,
            });
            navigate("/accounts");
        } catch (e) {
            console.log(e.response.status);
            if (e?.response?.status === 422) {
                HttpErrorHandler(dispatch, e?.response?.data.message);
            }
        }
    };
    console.log(props.userData);
    const deleteSelectedAddon = (index) => {
        const copy = [...selectedAddons];
        copy.splice(index, 1);
        setSelectedAddons(copy);
    };
    const deleteCreatedAddon = (index) => {
        const copy = [...createdAddons];
        copy.splice(index, 1);
        setCreatedAddons(copy);
    };
    if (addonsItemPriceIsLoading) {
        return (
            <Grid container item xs={12} justifyContent="center">
                <CircularProgress />
            </Grid>
        );
    }
    console.log(props.userData);
    return (
        <Grid container item direction="row" xs={12}>
            <Grid container item xs={6} direction="column" alignItems="center">
                <Grid container item xs={6}>
                    <AddonsList
                        deleteSelectedAddon={deleteSelectedAddon}
                        deleteCreatedAddon={deleteCreatedAddon}
                        dense
                        selectedAddons={selectedAddons}
                        createdAddons={createdAddons}
                    />
                </Grid>
            </Grid>
            <Grid container item xs={6} justify="center" direction="column" alignItems="center" spacing={4}>
                {/* <Grid container item justifyContent="center" spacing={4} style={{ marginTop: "0px" }}>
                    <Typography variant="h5" className={classes.textCenter + " " + classes.heading}>
                        Select addons
                    </Typography>
                    <Grid item xs={9}>
                        <Select
                            labelId="demo-mutiple-chip-label"
                            id="demo-mutiple-chip"
                            multiple
                            fullWidth
                            value={selectedAddons}
                            onChange={onAddonChange}
                            input={<OutlinedInput size="small" variant="outlined" className={classes.input} />}
                            renderValue={(selected) => (
                                <div className={classes.chips}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} className={classes.chip} />
                                    ))}
                                </div>
                            )}
                        >
                            {addonsItemPrice != null &&
                                addonsItemPrice.map((e) => {
                                    return (
                                        <MenuItem value={e.zoho_addon_code}>
                                            {e.name} - {e.period_unit} - ${e.price}
                                        </MenuItem>
                                    );
                                })}
                        </Select>
                    </Grid>
                </Grid> */}
                <Grid container item>
                    <Grid container justify="center" alignItems="center">
                        <Grid item sm={9} justify="center">
                            <Typography variant="h5" className={classes.textCenter + " " + classes.heading}>
                                Create a Addon
                            </Typography>
                            <Formik
                                validationSchema={object({
                                    addon_code: string().required(),
                                    price: number().typeError("A number is required").required(),
                                })}
                                initialValues={initialValues}
                                onSubmit={async (values, formikHelpers) => {
                                    values = { ...values, currency: props.currency };
                                    try {
                                        onCreateAddon(values);
                                        formikHelpers.resetForm();
                                        console.log("dfjaksdfjksdl");
                                    } catch (e) {
                                        if (e?.response?.status === 422) {
                                            formikHelpers.setErrors({
                                                organization_name: e?.response.data.message,
                                            });
                                        }
                                    }
                                }}
                            >
                                {({ values, errors, isSubmitting, isValidating, touched, setFieldValue, resetForm }) => (
                                    <Form>
                                        <FormGroup>
                                            <Field
                                                error={touched.name && errors.hasOwnProperty("name")}
                                                name="name"
                                                as={TextField}
                                                label="Addon name"
                                                variant="outlined"
                                                margin="dense"
                                                helperText={touched.name && errors?.name}
                                            />
                                        </FormGroup>
                                        {/* <FormGroup>
                                            <Field
                                                error={touched.currency && errors.hasOwnProperty("period")}
                                                name="period"
                                                as={TextField}
                                                label="Period"
                                                variant="outlined"
                                                fullWidth
                                                select
                                                margin="dense"
                                                helperText={touched.period && errors?.period}
                                            >
                                                <MenuItem value={"month"}>Monthly</MenuItem>
                                                <MenuItem value={"year"}>Anually</MenuItem>
                                            </Field>
                                        </FormGroup> */}
                                        <Field
                                            error={touched.price && errors.hasOwnProperty("price")}
                                            name="price"
                                            as={TextField}
                                            fullWidth
                                            margin="dense"
                                            label="Addon price ($)"
                                            variant="outlined"
                                            helperText={touched.price && errors?.price}
                                        ></Field>

                                        {console.log(values.features)}
                                        <FormGroup>
                                            <Field
                                                error={touched.addon_code && errors.hasOwnProperty("addon_code")}
                                                name="addon_code"
                                                as={CustomSelect}
                                                fullWidth
                                                margin="dense"
                                                label="Addon Code"
                                                variant="outlined"
                                                helperText={touched.addon_code && errors?.addon_code}
                                            >
                                                {addonsItemPrice?.map((ele, i) => (
                                                    <MenuItem key={i} value={ele}>
                                                        {ele}
                                                    </MenuItem>
                                                ))}
                                            </Field>
                                        </FormGroup>
                                        <Grid container direction="column" xs={12} alignContent="center" alignItems="center">
                                            <Button type="submit" color="primary" variant="contained" disabled={false}>
                                                {false ? (
                                                    <CircularProgress
                                                        style={{
                                                            marginRight: 10,
                                                            color: "white",
                                                        }}
                                                        size={16}
                                                    />
                                                ) : null}
                                                Add
                                            </Button>
                                        </Grid>
                                    </Form>
                                )}
                            </Formik>
                        </Grid>
                        <Grid container item xs={6} spacing={2}>
                            <Grid item xs={6}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={false}
                                    fullWidth
                                    onClick={() => {
                                        props.setActiveStep(1);
                                    }}
                                >
                                    {false ? (
                                        <CircularProgress
                                            style={{
                                                marginRight: 10,
                                                color: "white",
                                            }}
                                            size={16}
                                        />
                                    ) : null}
                                    Previous
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                    disabled={false}
                                    fullWidth
                                    onClick={() => subscribe()}
                                >
                                    {subscribeUserIsLoading ? (
                                        <CircularProgress
                                            style={{
                                                marginRight: 10,
                                                color: "white",
                                            }}
                                            size={16}
                                        />
                                    ) : null}
                                    Subscribe
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Addons;
