import React from 'react'
import PropTypes from "prop-types";
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import ZohoCustomer from "./ZohoCustomer/index"
import AllAccountsTable from "./AllAccountsTable"
import AdminUsersTable from './AdminUsers/AdminUsersTable';
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import AdminUser from './AdminUsers/Index';
import checkForRoles from 'utils/CheckForRoles';
import CheckForRolesHOC from 'utils/CheckForRolesHOC';
import { useSelector } from 'react-redux';
import VerficationLink from "./VerficationLink/index"

const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: "#FAFAFA",
    },
    root: {
        flexGrow: 1,
        marginLeft: 30,
        marginRight: 20,
    },
    tabPanel: {
        backgroundColor: "#FAFAFA",
    },
    tab: {
        textTransform: "capitalize",
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #000",
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        borderRadius: 10
    },
}));
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box p={0}>{children}</Box>}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {

    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const CustomerTabs = () => {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const role = useSelector(state => state.user.user.roles)
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (
        <div className={classes.root}>

            <AppBar position="static" className={classes.appBar} elevation={0}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="simple tabs example"
                    TabIndicatorProps={{ style: { backgroundColor: "#FD9567" } }}
                >
                    <Tab label="All Accounts" {...a11yProps(0)} className={classes.tab} />
                    <Tab label="Verfication Link" {...a11yProps(1)} className={classes.tab} />

                    {checkForRoles(['Super-Admin'], role) && <Tab label="Admin Users" {...a11yProps(2)} className={classes.tab} />}
                </Tabs>
            </AppBar>
            <Divider />
            <TabPanel value={value} index={0} className={classes.tabPanel}>
                <AllAccountsTable />
            </TabPanel>
            <TabPanel value={value} index={1} className={classes.tabPanel}>
                <VerficationLink />
            </TabPanel>
            <CheckForRolesHOC requiredRoles={['Super-Admin']}>
                <TabPanel value={value} index={2} className={classes.tabPanel}>
                    {/* <ZohoCustomer /> */}
                    <AdminUser />
                </TabPanel>
            </CheckForRolesHOC>
           

        </div >
    )
}

export default CustomerTabs