import { reduxApiWrapper as apiWrapper } from "utils/ReduxApiWrapper";
import { INVOICE_LIST_LOADED, INVOICE_LIST_LOADING } from "redux/actionTypes/actionTypes";
import { HttpErrorHandler } from "../../utils/ErrorHandlers/HttpErrorHandler";

const fetchInvoices = () => async (dispatch) => {
    apiWrapper(
        dispatch,
        INVOICE_LIST_LOADING,
        INVOICE_LIST_LOADED,
        {
            module: "subscription",
            method: "getAllInvoices",
        },
        HttpErrorHandler
    );
};

export { fetchInvoices };
