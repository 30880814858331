import { combineReducers } from "redux";

import notifierReducer from "./notifierReducer";
import { customerReducer } from "./customerReducer";
import { invoiceReducer } from "./invoiceReducer";
import { subscriptionReducer } from "./subscriptionReducer";
import { usersReducer } from "./userReducer";
import { dashboardReducer } from "./dashboardReducer";
const rootReducer = combineReducers({
    notification: notifierReducer,
    customer: customerReducer,
    invoice: invoiceReducer,
    subscription: subscriptionReducer,
    user: usersReducer,
    dashboard: dashboardReducer
});

export default rootReducer;
