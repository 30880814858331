
import React from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";

import PauseIcon from '@material-ui/icons/Pause';
import Tooltip from "@material-ui/core/Tooltip";
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import clsx from "clsx";
import { Link, Navigate, useNavigate } from "react-router-dom";
import useApi from "hooks/useApi";
import { useDispatch } from "react-redux";
import SuccessSnackbar from "utils/successSnackbar";
import { HttpErrorHandler } from "utils/ErrorHandlers/HttpErrorHandler";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CheckForPermissionHOC from "utils/CheckForPermissionHOC";
import WarningPopup from "common/WarningPopup";
import EditIcon from '@material-ui/icons/Edit';
const useStyles = makeStyles((theme) => ({
    icon: {
        color: "#A5AEB7",
        marginRight: 4,
        fontSize: 17,

        cursor: "pointer",
        transition: "initial",
        transitionDuration: "0.2s",
        "&:hover": {
            transform: "scale(1.2)",
        },
    },
    font15: {
        fontSize: 15,
    },
}));

export default function DBCustomerActionItems({ account_id, fontSize, subscription_status }) {
    const navigate = useNavigate();
    const classes = useStyles();
    const [, resumeAccountIsLoading, resumeAccount] = useApi();
    const [, pauseAccountIsLoading, pauseAccount] = useApi();
    const dispatch = useDispatch()
    const [PauseConsentOpen, setPauseConsentOpen] = React.useState(false);
    const [ResumeConsentOpen, setResumeConsentOpen] = React.useState(false);


    const resumeAccountHandler = async () => {
        try {
            await resumeAccount({
                module: "auth",
                method: "resumeAccount",

                returnResult: true,
                apiData: {
                    account_id: account_id
                },
                successToast: "Account Resumed",

            });
        }
        catch (e) {
            console.log(e)
        }
    };
    const pauseAccountHandler = async () => {
        await pauseAccount({
            module: "auth",
            method: "pauseAccount",
            returnResult: true,
            apiData: {
                account_id: account_id
            },
            successToast: "Account Paused",

        })

    };
    return (
        <div>
            <CheckForPermissionHOC requiredPermissions={["subscription.view"]}>

                <Tooltip title="Edit Subscription">

                    <EditIcon
                        className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                        onClick={(e) => {
                            navigate(`/subscription/unlimited/${account_id}`);
                        }}
                        fontSize="small"
                    />

                </Tooltip>
            </CheckForPermissionHOC>
            <CheckForPermissionHOC requiredPermissions={["account.pause"]}>
                <WarningPopup openPopup={PauseConsentOpen} closePopup={() => setPauseConsentOpen(false)} headingText="Are you sure to pause this account" submitAction={pauseAccountHandler} submitActionIsLoading={pauseAccountIsLoading} />
                <WarningPopup openPopup={ResumeConsentOpen} closePopup={() => setResumeConsentOpen(false)} headingText="Are you sure to resume this account" submitAction={resumeAccountHandler} submitActionIsLoading={resumeAccountIsLoading} />

                <Tooltip title="Resume Account">
                    {resumeAccountIsLoading ? (
                        <CircularProgress size={15} />
                    ) : (
                        <PlayArrowIcon
                            className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                            onClick={(e) => {
                                e.stopPropagation();
                                setResumeConsentOpen(true);
                            }}
                            fontSize="small"
                        />
                    )}
                </Tooltip>
            </CheckForPermissionHOC>
            <CheckForPermissionHOC requiredPermissions={["account.pause"]}>
                {subscription_status !== 'unpaid' &&
                    <Tooltip title="Pause Account">
                        {pauseAccountIsLoading ? (
                            <CircularProgress size={15} />
                        ) : (
                            <PauseIcon
                                className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setPauseConsentOpen(true);
                                }}
                                fontSize="small"
                            />
                        )}
                    </Tooltip>}
            </CheckForPermissionHOC>
            <CheckForPermissionHOC requiredPermissions={["usage.view"]}>

                <Tooltip title="Account details">
                    <AccountCircleIcon
                        className={clsx(classes.icon, { [classes.font15]: fontSize !== undefined })}
                        onClick={(e) => {

                            navigate(`/accounts/details/${account_id}`);
                        }}
                        fontSize="small"
                    />
                </Tooltip>
            </CheckForPermissionHOC>

        </div>
    );
}
