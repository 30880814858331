import { Typography, MenuItem, IconButton } from "@material-ui/core";
import React from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Field, Form, Formik, useField } from "formik";
import { makeStyles, CircularProgress, InputAdornment, Chip, FormLabel } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { object, string, number, array } from "yup";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Divider from "@material-ui/core/Divider";
import useApi from "hooks/useApi";

const useStyle = makeStyles((theme) => {
    return {
        textCenter: {
            textAlign: "center",
        },
        chips: {
            display: "flex",
            flexWrap: "wrap",
        },
        chip: {
            height: 22,
            fontSize: 9,
            marginRight: 5,
        },
        primColor: {
            color: theme.palette.primary.main,
        },
        hr: {
            marginTop: 20,
            marginBottom: 20,
        },
        heading: {
            color: "#2C2C2C",
        },
        inputLabel: {
            position: "absolute",
            left: 10,
            top: -10,
            paddingLeft: 2,
            paddingBottom: 3,
            backgroundColor: "#FFFFFF",
            paddingRight: 45,
            zIndex: 1000,
        },
        select: {
            margin: "13px 0px",
        },
    };
});
const CustomSelect = (props) => {
    const classes = useStyle();
    const [field] = useField({
        name: props.name,
        type: "select",
        value: props.value,
    });
    console.log(field);
    return (
        <FormControl fullWidth className={classes.select}>
            <InputLabel className={classes.inputLabel}>{props.label}</InputLabel>
            <Select {...props} {...field} onChange={props.onChange}>
                {props.children}
            </Select>
        </FormControl>
    );
};

const addMore = (setFieldValue, values) => {
    const tier = values.tier;
    const tierObject = {
        from: null,
        to: null,
        price: null,
    };
    tier.push(tierObject);
    setFieldValue("tier", tier);
};
const deleteAddon = (setFieldValue, values, index) => {
    const tier = values.tier;
    values.tier.splice(index, 1);
    setFieldValue("tier", tier);
};

const CreatePlan = (props) => {
    const initialValues = {
        ...props.userData.plan,
    };
    const [createPlanResponse, createPlanIsloading, createPlan] = useApi();
    console.log(createPlanResponse);
    const updateUser = (res) => {
        console.log();
        props.setUserData((plan) => ({
            ...props.userData,
            plan: res,
            isSelect: false,
        }));
    };
    React.useEffect(() => { });
    const classes = useStyle();
    return (
        <Grid container xs={12} direction="column" justify="center" alignItems="center">
            <Grid item sm={9} xs={12} justify="center" style={{ minWidth: "470px" }}>
                <Typography variant="h5">Create a plan</Typography>
                <Formik
                    validationSchema={object({
                        price_per_million: number().required(),
                        base_rows: number().required(),
                        period: string().required(),
                        features: array().min(1),
                        price: number().typeError("A number is required").required(),
                        trial_period: number().typeError("A number is required").required(),
                        // tier: array().of(
                        //     object().shape({
                        //         from: number().typeError("A number is required"),
                        //         to: number().typeError("A number is required"),
                        //         price: number().typeError("A number is required"),
                        //         // Rest of your amenities object properties
                        //     })
                        // ),
                    })}
                    initialValues={initialValues}
                    onSubmit={async (values, formikHelpers) => {
                        updateUser(values);
                        props.setActiveStep(2);
                    }}
                >
                    {({ values, errors, isSubmitting, isValidating, touched, setFieldValue }) => (
                        <Form>
                            <FormGroup>
                                <Field
                                    name="price_per_million"
                                    error={touched.id && errors.hasOwnProperty("price_per_million")}
                                    as={TextField}
                                    label="Price per million rows ($)"
                                    variant="outlined"
                                    margin="dense"
                                    helperText={touched.price_per_million && errors?.price_per_million}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    error={touched.base_rows && errors.hasOwnProperty("base_rows")}
                                    name="base_rows"
                                    as={TextField}
                                    label="Base Rows (M)"
                                    variant="outlined"
                                    margin="dense"
                                    helperText={touched.base_rows && errors?.base_rows}
                                />
                            </FormGroup>

                            <FormGroup>
                                <Field
                                    error={touched.currency && errors.hasOwnProperty("period")}
                                    name="period"
                                    as={TextField}
                                    label="Period"
                                    variant="outlined"
                                    fullWidth
                                    select
                                    margin="dense"
                                    helperText={touched.period && errors?.period}
                                >
                                    <MenuItem value={"month"}>Monthly</MenuItem>
                                    <MenuItem value={"year"}>Anually</MenuItem>
                                </Field>
                            </FormGroup>
                            <Field
                                error={touched.price && errors.hasOwnProperty("price")}
                                name="price"
                                as={TextField}
                                fullWidth
                                margin="dense"
                                label="Plan price ($)"
                                variant="outlined"
                                helperText={touched.price && errors?.price}
                            ></Field>
                            <FormGroup>
                                <Field
                                    error={touched.trial_period && errors.hasOwnProperty("trial_period")}
                                    name="trial_period"
                                    as={TextField}
                                    InputProps={{
                                        endAdornment: <InputAdornment position="end">days</InputAdornment>,
                                    }}
                                    label="Trial Period"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    helperText={touched.trial_period && errors?.trial_period}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    error={touched.features && errors.hasOwnProperty("features")}
                                    name="features"
                                    as={CustomSelect}
                                    multiple
                                    fullWidth
                                    renderValue={(selected) => (
                                        <div className={classes.chips}>
                                            {selected.map((value) => (
                                                <Chip key={value} label={value} className={classes.chip} />
                                            ))}
                                        </div>
                                    )}
                                    margin="dense"
                                    label="Features"
                                    variant="outlined"
                                    helperText={touched.features && errors?.features}
                                >
                                    <MenuItem key={0} value={"DATAPIPELINE"}>
                                        DATAPIPELINE
                                    </MenuItem>
                                    <MenuItem key={0} value={"DC_MANAGED_WAREHOUSE"}>
                                        DC MANAGED WAREHOUSE
                                    </MenuItem>
                                    <MenuItem key={0} value={"TRANSFORMATION"}>
                                        TRANSFORMATION
                                    </MenuItem>
                                    <MenuItem key={0} value={"REVERSE"}>
                                        REVERSE
                                    </MenuItem>
                                    <MenuItem key={0} value={"DATAWAREHOUSE"}>
                                        DATAWAREHOUSE
                                    </MenuItem>
                                </Field>
                            </FormGroup>
                            {/* <FormLabel component="legend" style={{ marginTop: 20, marginBottom: 20 }}>
                                Add Metered Tiers
                            </FormLabel> */}
                            {/* <Grid item container xs={12} spacing={2}>
                                <Grid item xs={4} container direction="row" justifyContent="center">
                                    <Grid item container direction="column" xs={12}>
                                        <InputLabel htmlFor="outlined-adornment-amount">From (Millions)</InputLabel>
                                    </Grid>

                                    {values.tier.map((e, i) => {
                                        return (
                                            <Grid item container direction="column" xs={12} alignItems="center">
                                                <FormGroup>
                                                    <Field
                                                        error={
                                                            (touched?.tier === undefined
                                                                ? touched?.tier
                                                                : touched?.tier[i]?.from) &&
                                                            errors?.hasOwnProperty("tier") &&
                                                            errors?.tier[i]?.from
                                                        }
                                                        name={`tier[${i}].from`}
                                                        key={i}
                                                        value={values.tier[i]?.from}
                                                        // onChange={(e) => onToTieredChange(e, setFieldValue, values, 0)}
                                                        as={TextField}
                                                        label="From"
                                                        variant="outlined"
                                                        margin="dense"
                                                    />
                                                </FormGroup>
                                            </Grid>
                                        );
                                    })}

                                    <Grid item container direction="column" xs={12} alignItems="center">
                                        <FormGroup>
                                            <Field
                                                error={
                                                    (touched?.tier === undefined ? touched?.lastTier : touched?.lastTier?.from) &&
                                                    errors?.hasOwnProperty("lastTier") &&
                                                    errors?.lastTier?.from
                                                }
                                                name={`lastTier.from`}
                                                key={values.tier.length - 1}
                                                value={values.lastTier?.from}
                                                // onChange={(e) => onToTieredChange(e, setFieldValue, values, 0)}
                                                as={TextField}
                                                label="From"
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container direction="row" justifyContent="center">
                                    <Grid item container direction="column" xs={12}>
                                        <InputLabel htmlFor="outlined-adornment-amount">To (Millions)</InputLabel>
                                    </Grid>
                                    {values.tier.map((e, i) => {
                                        return (
                                            <Grid item container direction="column" xs={12} alignItems="center">
                                                <FormGroup>
                                                    <Field
                                                        error={
                                                            (touched?.tier === undefined
                                                                ? touched?.tier
                                                                : touched?.tier[i]?.to) &&
                                                            errors?.hasOwnProperty("tier") &&
                                                            errors?.tier[i]?.to
                                                        }
                                                        name={`tier[${i}].to`}
                                                        key={i}
                                                        value={values.tier[i]?.to}
                                                        // onChange={(e) => onToTieredChange(e, setFieldValue, values, 0)}
                                                        as={TextField}
                                                        label="To"
                                                        variant="outlined"
                                                        margin="dense"
                                                    />
                                                </FormGroup>
                                            </Grid>
                                        );
                                    })}
                                    <Grid item container direction="column" xs={12} alignItems="center">
                                        <FormGroup>
                                            <Field
                                                as={TextField}
                                                label="& above"
                                                name={`lastTier.to`}
                                                disabled
                                                variant="outlined"
                                                margin="dense"
                                            />
                                        </FormGroup>
                                    </Grid>
                                </Grid>
                                <Grid item xs={4} container direction="row" justifyContent="center">
                                    <Grid item container direction="column" xs={12}>
                                        <InputLabel htmlFor="outlined-adornment-amount">Price</InputLabel>
                                    </Grid>
                                    {values.tier.map((e, i) => {
                                        return (
                                            <Grid container item justifyContent="center" spacing={1}>
                                                <Grid item container xs={9} alignItems="center" justifyContent="center">
                                                    <FormGroup>
                                                        <Field
                                                            error={
                                                                (touched?.tier === undefined
                                                                    ? touched?.tier
                                                                    : touched?.tier[i]?.price) &&
                                                                errors?.hasOwnProperty("tier") &&
                                                                errors?.tier[i]?.price
                                                            }
                                                            name={`tier[${i}].price`}
                                                            key={i}
                                                            value={values.tier[i]?.price}
                                                            // onChange={(e) => onToTieredChange(e, setFieldValue, values, 0)}
                                                            as={TextField}
                                                            label="Price"
                                                            variant="outlined"
                                                            margin="dense"
                                                        />
                                                    </FormGroup>
                                                </Grid>
                                                <Grid container item xs={3} alignItems="center" justifyContent="center">
                                                    <IconButton
                                                        onClick={() => deleteAddon(setFieldValue, values, i)}
                                                        size="small"
                                                        style={{ margin: "2px" }}
                                                        disableRipple
                                                        disableFocusRipples
                                                    >
                                                        <CloseIcon />
                                                    </IconButton>
                                                </Grid>
                                            </Grid>
                                        );
                                    })}
                                    <Grid container item justifyContent="center" spacing={1}>
                                        <Grid item container xs={9} alignItems="center" justifyContent="center">
                                            <FormGroup>
                                                <Field
                                                    error={
                                                        (touched?.tier === undefined
                                                            ? touched?.lastTier
                                                            : touched?.lastTier?.price) &&
                                                        errors?.hasOwnProperty("tier") &&
                                                        errors?.lastTier?.price
                                                    }
                                                    name={`lastTier.price`}
                                                    key={values.lastTier}
                                                    value={values.lastTier?.price}
                                                    // onChange={(e) => onToTieredChange(e, setFieldValue, values, 0)}
                                                    as={TextField}
                                                    label="Price"
                                                    variant="outlined"
                                                    margin="dense"
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid container item xs={3} alignItems="center" justifyContent="center"></Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid xs={12} item container justifyContent="flex-end">
                                <Button
                                    color="primary"
                                    size="small"
                                    variant="contained"
                                    onClick={() => addMore(setFieldValue, values)}
                                >
                                    Add More
                                </Button>
                            </Grid> */}
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button
                                        type="submit"
                                        color="primary"
                                        variant="contained"
                                        disabled={false}
                                        fullWidth
                                        onClick={() => {
                                            props.setActiveStep(0);
                                        }}
                                    >
                                        {false ? (
                                            <CircularProgress
                                                style={{
                                                    marginRight: 10,
                                                    color: "white",
                                                }}
                                                size={16}
                                            />
                                        ) : null}
                                        Previous
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button type="submit" color="primary" variant="contained" disabled={false} fullWidth>
                                        {false ? (
                                            <CircularProgress
                                                style={{
                                                    marginRight: 10,
                                                    color: "white",
                                                }}
                                                size={16}
                                            />
                                        ) : null}
                                        Next
                                    </Button>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </Grid>
        </Grid>
    );
};

export default CreatePlan;
