import React from "react";
import { getTransformationRunLogs } from "services/Api/api";
import moment from "moment";
import useApi from "./useApi";

const formatTableData = (data) => {
    data?.forEach((el) => {
        if (el.error_list !== null && el.error_list !== undefined) el.error = el.error_list.join(", ");
        if (el.ts_created === undefined) {
            const date = el.created_at;
            const modifiedDate = moment(date).format("ddd, DD MMM YYYY HH:mm:ss") + " GMT";
            el.ts_created = moment(modifiedDate).format("YYYY-MM-DD, hh:mm:ss A");
        } else {
            el.ts_created = moment(el.ts_created).format("YYYY-MM-DD, hh:mm:ss A");
        }
    });
    console.log(data);
    return data;
};

const useTableApi = (url, refresh, isReverse = false) => {
    const [tableData, setTableData] = React.useState([]);
    const [pageSize, setPageSize] = React.useState(10);
    const [filter, setFilter] = React.useState(null);
    const [page, setPage] = React.useState(1);
    const [tableLoading, setTableLoading] = React.useState(false);
    const [count, setCount] = React.useState(null);
    const onPageChange = (page) => setPage(page);
    const [response, isLoading, makeRequest] = useApi();
    const onFilterChange = (value) => {
        setPage(1);
        setFilter(value);
    };
    const onPageSizeChange = (size) => {
        setPage(1);
        setPageSize(size);
    };
    React.useEffect(() => {
        let queryParameter = `?page=${page}&per_page=${pageSize}`;
        if (filter !== null && filter !== "") {
            queryParameter += `&search=${filter}`;
        }
        setTableLoading(true);
        // if (isReverse === true) {
        //     url = config.reverseApiUrl + url;
        // }
        const handleDataFetch = async (url, queryParameter) => {
            await makeRequest({
                module: "configuredReport",
                method: "getTransformationRunLogs",
                apiData: {
                    url: url,
                    queryParameter: queryParameter,
                },
            });
            if (response) {
                console.log(response);
                setCount(response.data.total);
                setTableData(formatTableData(response.data.data));
                setTableLoading(false);
            }
        };
        getTransformationRunLogs(url, queryParameter)
            .then((res) => {
                setCount(res.data.total);
                setTableData(formatTableData(res.data.data));
                setTableLoading(false);
                console.log(res);
            })
            .catch((e) => {
                setTableLoading(false);
            });
        //  `handleDataFetch(url, queryParameter);
        console.log(url + queryParameter);
    }, [page, filter, pageSize, refresh]);

    React.useEffect(() => {
        console.log(response);
    }, [response]);

    return [tableData, count, page, pageSize, tableLoading, onPageChange, onFilterChange, onPageSizeChange];
};

export default useTableApi;
