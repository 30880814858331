import { Typography, MenuItem } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { Field, Form, Formik } from "formik";
import { makeStyles, CircularProgress, Switch } from "@material-ui/core";
import { FormGroup } from "@material-ui/core";
import { object, string, ref } from "yup";
import logo from "assets/images/loginPage/dcLogo.png";
import useApi from "hooks/useApi";
import { Country, State } from "country-state-city";
import "react-phone-input-2/lib/material.css";
import ReactPhoneInput from "react-phone-input-2";
import { useNavigate } from "react-router";

const useStyle = makeStyles((theme) => {
    return {
        banner: {},
        textCenter: {
            textAlign: "center",
        },
        primColor: {
            color: theme.palette.primary.main,
        },
        hr: {
            marginTop: 20,
            marginBottom: 20,
        },
        welcome: {
            color: "#2C2C2C",
        },
        socialShare: {
            fontSize: "10px",
            color: "#626262",
            boxShadow: "0px 0px 3px #00000029",
            border: "none",
            marginTop: 25,
            height: 34,
            marginRight: 18,
        },
        logoContainer: {
            marginBottom: 5,
            marginTop: -10,
        },
    };
});

const UserRegister = (props) => {
    const initialValues = {
        ...props.userData.registerData,
    };
    const [availableCountry, setAvalaibleCountry] = useState([]);
    const navigate = useNavigate()
    const [availableState, setAvalaibleState] = useState([]);
    const [withZoho, setWithZoho] = useState(true);
    const updateUser = () => {
        console.log(props);
    };
    const classes = useStyle();
    const [user, loading, setUser] = useApi();
    const [, registerWithoutZohoIsLoading, registerWithoutZoho] = useApi();

    // const [gst_treatment, setGstTreatment] = useState();
    useEffect(() => {
        setAvalaibleCountry(Country.getAllCountries());
        console.log(availableCountry);
        console.log(props.selectedCountry);
        console.log(availableState);
        if (props.selectedCountry) {
            setAvalaibleState(State.getStatesOfCountry(props.selectedCountry.isoCode));
        }
    }, []);
    const onPhoneNumberChange = (e) => {
        console.log(e);
    };
    const handleSwitchChange = (e) => {
        setWithZoho(!e.target.checked);
    };
    const registerWithoutZohoFunc = async (values, formikHelpers) => {
        try {
            await registerWithoutZoho({
                module: "auth",
                method: "adminRegisterWithoutZoho",
                successToast: "Registeration Successfull",
                apiData: {
                    data: values,
                },
                throwError: true,
            });
            navigate("/accounts");

        } catch (e) {
            console.log(e);
            if (e?.response?.status === 422) {
                formikHelpers.setErrors({
                    email: e?.response.data.message,
                });
            }
        }
    };

    useEffect(() => {
        if (props.selectedCountry) {
            setAvalaibleState(State.getStatesOfCountry(props.selectedCountry?.isoCode));
        }
    }, [props.selectedCountry, props.activeStep]);
    return (
        <Grid container justify="center">
            <Grid item sm={5} xs={10}>
                <Grid container justify="center" alignItems="center">
                    <Grid item sm={9} justify="center">
                        <Grid xs={12} container alignItems="center" justifyContent="center" style={{ marginBottom: 20 }}>
                            <Grid item>
                                <Typography variant="body1" style={{ fontWeight: 500 }}>
                                    Register with zoho{" "}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Switch onChange={handleSwitchChange} color="primary" />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" style={{ fontWeight: 500 }}>
                                    Register without zoho
                                </Typography>
                            </Grid>
                        </Grid>
                        {/* <Typography variant="h6" className={classes.textCenter + " " + classes.welcome}>
                            Welcome To
                        </Typography>
                        <Grid container justify="center" className={classes.logoContainer}>
                            <img src={logo} alt="wc logo" />
                        </Grid> */}

                        <Formik
                            validationSchema={object({
                                email: string().required(),
                                password: string().required().min(5),
                                confirmPassword: string().when("password", {
                                    is: (val) => (val && val.length > 0 ? true : false),
                                    then: string().oneOf([ref("password")], "Both password need to be the same"),
                                }),
                                name: string().required(),
                                organization_name: string()
                                    .required()
                                    .matches(/^[a-zA-Z0-9 ]+$/, "Only alphanumeric characters and spaces are allowed."),
                                country: withZoho ? string().required() : null,
                                state: withZoho ? string().required() : null,
                                phone_number: withZoho ? string().required().min(6) : null,
                            })}
                            initialValues={initialValues}
                            onSubmit={async (values, formikHelpers) => {
                                console.log(withZoho);
                                if (withZoho) {
                                    let st = availableState.filter((state) => state.name === values.state)[0];
                                    let stateCode = st.isoCode;
                                    values = { ...values, state_code: stateCode };

                                    console.log(values);
                                    props.setUserData((prevState) => ({
                                        ...props.userData,
                                        registerData: values,
                                    }));
                                    props.setActiveStep(1);
                                } else {
                                    console.log(values);
                                    registerWithoutZohoFunc(values, formikHelpers);
                                }
                            }}
                        >
                            {({ values, errors, isSubmitting, isValidating, touched, setFieldValue, handleChange }) => (
                                <Form>
                                    <FormGroup>
                                        <Field
                                            name="email"
                                            error={touched.email && errors.hasOwnProperty("email")}
                                            as={TextField}
                                            label="Email"
                                            variant="outlined"
                                            fullWidth
                                            margin="dense"
                                            helperText={touched.email && errors?.email}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Field
                                            error={touched.name && errors.hasOwnProperty("name")}
                                            name="name"
                                            as={TextField}
                                            label="Name"
                                            variant="outlined"
                                            fullWidth
                                            margin="dense"
                                            helperText={touched.name && errors?.name}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Field
                                            error={touched.organization_name && errors.hasOwnProperty("organization_name")}
                                            name="organization_name"
                                            as={TextField}
                                            label="Organization Name"
                                            variant="outlined"
                                            fullWidth
                                            type="text"
                                            margin="dense"
                                            helperText={touched.organization_name && errors?.organization_name}
                                        />
                                    </FormGroup>

                                    <FormGroup>
                                        <Field
                                            error={touched.password && errors.hasOwnProperty("password")}
                                            name="password"
                                            as={TextField}
                                            label="Password"
                                            variant="outlined"
                                            fullWidth
                                            type="password"
                                            margin="dense"
                                            helperText={touched.password && errors?.password}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Field
                                            error={touched.confirmPassword && errors.hasOwnProperty("confirmPassword")}
                                            name="confirmPassword"
                                            as={TextField}
                                            label="Confirm Password"
                                            variant="outlined"
                                            fullWidth
                                            type="password"
                                            margin="dense"
                                            helperText={touched.confirmPassword && errors?.confirmPassword}
                                        />
                                    </FormGroup>
                                    <FormGroup>
                                        <Field
                                            error={touched.country && errors.hasOwnProperty("country")}
                                            name="country"
                                            as={TextField}
                                            select
                                            label="country"
                                            variant="outlined"
                                            fullWidth
                                            onChange={(e) => {
                                                props.setSelectedCountry(
                                                    availableCountry.find((c) => {
                                                        return c.name == e?.target?.value;
                                                    })
                                                );
                                                setFieldValue("state", "");
                                                handleChange(e);
                                            }}
                                            margin="dense"
                                            helperText={touched.country && errors?.country}
                                        >
                                            {availableCountry.map((country) => (
                                                <MenuItem key={country.name} value={country.name}>
                                                    {country.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormGroup>
                                    <FormGroup>
                                        <Field
                                            error={touched.state && errors.hasOwnProperty("state")}
                                            name="state"
                                            as={TextField}
                                            select
                                            label="State"
                                            variant="outlined"
                                            fullWidth
                                            margin="dense"
                                            helperText={touched.state && errors?.state}
                                        >
                                            {availableState?.map((state) => (
                                                <MenuItem key={state.name} value={state.name}>
                                                    {state.name}
                                                </MenuItem>
                                            ))}
                                        </Field>
                                    </FormGroup>
                                    <FormGroup>
                                        <ReactPhoneInput
                                            inputStyle={{
                                                width: "100%",
                                                height: "35px",
                                                fontSize: "13px",
                                                borderRadius: "5px",
                                            }}
                                            containerStyle={{
                                                marginTop: 10,
                                            }}
                                            value={values.phone_number}
                                            specialLabel="phone number"
                                            name="phone_number"
                                            type="tel"
                                            isValid={!errors.hasOwnProperty("phone_number")}
                                            variant="outlined"
                                            defaultValue={values.phone_number}
                                            defaultCountry={"India"}
                                            onChange={(phone, country) => {
                                                setFieldValue("phone_number", phone);
                                                handleChange("phone_number");
                                            }}
                                        />
                                        {touched.phone_number && errors.hasOwnProperty("phone_number") && (
                                            <Typography style={{ color: "red", fontSize: 12, marginTop: 5, marginLeft: 15 }}>
                                                {touched.phone_number && errors?.phone_number}
                                            </Typography>
                                        )}
                                    </FormGroup>

                                    {withZoho ? (
                                        <Button type="submit" color="primary" variant="contained" disabled={loading} fullWidth style={{ marginTop: 20 }}>
                                            {loading ? (
                                                <CircularProgress
                                                    style={{
                                                        marginRight: 10,
                                                        color: "white",
                                                    }}
                                                    size={16}
                                                />
                                            ) : null}
                                            Next
                                        </Button>
                                    ) : (
                                        <Button type="submit" color="primary" variant="contained" fullWidth style={{ marginTop: 20 }}>
                                            {registerWithoutZohoIsLoading ? (
                                                <CircularProgress
                                                    style={{
                                                        marginRight: 10,
                                                        color: "white",
                                                    }}
                                                    size={16}
                                                />
                                            ) : null}
                                            Register without zoho
                                        </Button>
                                    )}
                                </Form>
                            )}
                        </Formik>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default UserRegister;
