import React from "react";

import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Tooltip from "@material-ui/core/Tooltip";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { Link } from "react-router-dom";
import Box from "@material-ui/core/Box";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import data_channel_logo from "assets/data_channel_logo.png";
import { makeStyles } from "@material-ui/core/styles";
import CardMembershipIcon from "@material-ui/icons/CardMembership";
import { Typography } from "@material-ui/core";
import ReceiptIcon from "@material-ui/icons/Receipt";
import GroupIcon from "@material-ui/icons/Group";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import CheckForRolesHOC from "utils/CheckForRolesHOC";

const useStyles = makeStyles((theme) => ({
    logo: {
        display: "flex",
        justifyContent: "center",
        marginBottom: 50,
        marginTop: 20,
    },
    listitem: {
        marginBottom: 10,
        display: "flex",
        flexDirection: "column",
    },
    iconcontainer: {
        justifyContent: "center",
    },
    icon: {
        fontSize: 25,
    },
    subheading: {
        fontSize: 8,
    },
}));

export default function Nav(props) {
    const classes = useStyles();
    return (
        <Drawer variant="permanent">
            <Box className={classes.logo}>
                <Link to="/" color="primary" underline="none">
                    <img src={data_channel_logo} alt="DataChannel Logo"></img>
                </Link>
            </Box>

            <List>
                {/* <Link to="/" color="primary" underline="none">
                    <ListItem button className={classes.listitem}>
                        <Tooltip title="Main Dashboard">
                            <ListItemIcon classes={{ root: classes.iconcontainer }}>
                                <HomeOutlinedIcon classes={{ root: classes.icon }} />
                            </ListItemIcon>
                        </Tooltip>
                        <Typography className={classes.subheading} variant="subtitle2">
                            Dashboard
                        </Typography>
                    </ListItem>
                </Link> */}
                <CheckForRolesHOC requiredRoles={['Account-Admin', 'Super-Admin', 'Support', 'Finance']}>
                    <Link to="/accounts" color="primary" underline="none">
                        <ListItem button className={classes.listitem}>
                            <Tooltip title="View Accounts">
                                <ListItemIcon
                                    classes={{
                                        root: classes.iconcontainer,
                                    }}
                                >
                                    <GroupIcon classes={{ root: classes.icon }} />
                                </ListItemIcon>
                            </Tooltip>
                            <Typography className={classes.subheading} variant="subtitle2">
                                Accounts
                            </Typography>
                        </ListItem>
                    </Link>
                </CheckForRolesHOC>
                <CheckForRolesHOC requiredRoles={['Super-Admin', 'Support',]}>
                    <Link to="/onboarding" color="primary" underline="none">
                        <ListItem button className={classes.listitem}>
                            <Tooltip title="Add new Customers">
                                <ListItemIcon classes={{ root: classes.iconcontainer }}>
                                    <GroupAddIcon classes={{ root: classes.icon }} />
                                </ListItemIcon>
                            </Tooltip>
                            <Typography className={classes.subheading} variant="subtitle2">
                                Onboarding
                            </Typography>
                        </ListItem>
                    </Link>
                </CheckForRolesHOC>

                <CheckForRolesHOC requiredRoles={['Super-Admin', 'Support', 'Sales', 'Finance']}>
                    <Link to="/subscriptions" color="primary" underline="none">
                        <ListItem button className={classes.listitem}>
                            <Tooltip title="View all subscriptions">
                                <ListItemIcon
                                    classes={{
                                        root: classes.iconcontainer,
                                    }}
                                >
                                    <CardMembershipIcon classes={{ root: classes.icon }} />
                                </ListItemIcon>
                            </Tooltip>
                            <Typography className={classes.subheading} variant="subtitle2">
                                Subscription
                            </Typography>
                        </ListItem>
                    </Link>
                </CheckForRolesHOC>
                <CheckForRolesHOC requiredRoles={['Super-Admin', 'Finance']}>
                    <Link to="/invoices" color="primary" underline="none">
                        <ListItem button className={classes.listitem}>
                            <Tooltip title="View all Invoices">
                                <ListItemIcon
                                    classes={{
                                        root: classes.iconcontainer,
                                    }}
                                >
                                    <ReceiptIcon classes={{ root: classes.icon }} />
                                </ListItemIcon>
                            </Tooltip>
                            <Typography className={classes.subheading} variant="subtitle2">
                                Invoice
                            </Typography>
                        </ListItem>
                    </Link>
                </CheckForRolesHOC>

                {/* <Link to="/insights" color="primary" underline="none">
					<ListItem button className={classes.listitem}>
						<Tooltip title="Insights">
							<ListItemIcon classes={{ root: classes.iconcontainer }}>
								<PieChartOutlinedIcon classes={{ root: classes.icon }} />
							</ListItemIcon>
						</Tooltip>
						<Typography variant="subtitle2">Insights</Typography>
					</ListItem>
				</Link> */}
            </List>
        </Drawer>
    );
}
