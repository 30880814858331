import React from "react";
import { Routes, Route } from "react-router-dom";
import Auth from "services/Auth";
import Onboarding from "components/Onboarding/index";
import Callback from "components/Callback/index";
import Customers from "components/Customer/index";
import Subscriptions from "components/Subscription/Index";
import Layout from "components/Layout/index";
import { useLocation } from "react-router-dom";
import Dashboard from "components/Dashboard/Index";
import Invoicess from "components/Invoices/Index";
import AddUsage from "components/Invoices/AddUsage.js";
import CustomerInvoices from "components/Invoices/CustomerInvoice";
import SubscriptionDetail from "components/Subscription/SubscriptionDetails";
import AccountDetails from "components/Customer/AccountDetails/index";
const Routing = () => {
    const location = useLocation();
    return (
        <Routes location={location} key={location.key}>
            <Route path="/callback" element={<Callback />}></Route>
            <Route
                path="/"
                element={
                    <Auth>
                        <Layout comp={Customers} pageTitle="Accounts" />
                    </Auth>
                }
            ></Route>
            <Route
                path="/onboarding"
                element={
                    <Auth>
                        <Layout comp={Onboarding} pageTitle="Customer Onboarding" />
                    </Auth>
                }
            ></Route>
            <Route
                path="/accounts"
                element={
                    <Auth>
                        <Layout comp={Customers} pageTitle="Accounts" />
                    </Auth>
                }
            ></Route>
            <Route
                path="/subscriptions"
                element={
                    <Auth>
                        <Layout comp={Subscriptions} pageTitle="Subscriptions" />
                    </Auth>
                }
            ></Route>
            <Route
                path="/subscription/:subscription_id/:account_id"
                element={
                    <Auth>
                        <Layout comp={SubscriptionDetail} pageTitle="Subscription Detail" />
                    </Auth>
                }
            ></Route>
            <Route
                path="/invoices"
                element={
                    <Auth>
                        <Layout comp={Invoicess} pageTitle="Invoices" />
                    </Auth>
                }
            ></Route>
            <Route
                path="/invoices/addUsage/:invoice_id/:customer_id"
                element={
                    <Auth>
                        <Layout comp={AddUsage} pageTitle="Add Usage" />
                    </Auth>
                }
            ></Route>
            <Route
                path="/customer/invoices/:customer_id"
                element={
                    <Auth>
                        <Layout comp={CustomerInvoices} pageTitle="Invoices" />
                    </Auth>
                }
            ></Route>
            <Route
                path="/accounts/details/:account_id"
                element={
                    <Auth>
                        <Layout comp={AccountDetails} pageTitle="Account Details" />
                    </Auth>
                }
            ></Route>
        </Routes>
    );
};

export default Routing;
