import React, { useEffect } from "react";
import { Typography } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Grid, Paper } from "@material-ui/core";
import StepConnector from "@material-ui/core/StepConnector";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import UserRegister from "./UserRegister";
import Plan from "./Plan/index";
import Addons from "./Addon";
import CheckForPermission from "utils/CheckForPermission";
import { useSelector } from "react-redux";
function getSteps() {
    return [
        { label: "Register User", subHeading: "register a user" },
        { label: "Plan", subHeading: "create a plan" },
        { label: "Addon", subHeading: "create or select addons" },
    ];
}

const useStyles = makeStyles((theme) => ({
    root: {},
    backButton: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    itemContainer: {
        marginTop: 20,
    },
    stepperContainer: {
        height: "100%",
        padding: 30,
    },
    hr: {
        marginTop: 45,
        marginBottom: 5,
    },
}));
const ColorlibConnector = withStyles({
    alternativeLabel: {
        top: 22,
    },
    active: {
        "& $line": {
            backgroundColor: "#FD9E74",
        },
    },
    completed: {
        "& $line": {
            backgroundColor: "#FD9E74",
        },
    },
    line: {
        height: 3,
        border: 0,
        backgroundColor: "#eaeaf0",
        borderRadius: 1,
        marginTop: -10,
    },
})(StepConnector);

const Index = () => {
    const classes = useStyles();
    const [checked] = React.useState(false);
    const [maxStepSoFar, setMaxStepSoFar] = React.useState(0);
    const [selectedCountry, setSelectedCountry] = React.useState(null);
    const [activeStep, setActiveStep] = React.useState(0);
    const [selectedAddons, setSelectedAddons] = React.useState([]);
    const [createdAddons, setCreatedAddons] = React.useState([]);
    const [userData, setUserData] = React.useState({
        registerData: {
            email: "",
            password: "",
            confirmPassword: "",
            name: "",
            organization_name: "",
            country: "",
            state: "",
            phone_number: "",
            state_code: "",
        },
        isSelect: false,
        plan: {
            price_per_million: null,
            base_rows: null,
            features: [],
            period: "",
            price: null,
            trial_period: null,
        },
        planPrice: null,
        createdAddons: [],
        selectedAddons: [],
    });
    const userState = useSelector((state) => state.user.user)
    const permissions = userState.permissions


    React.useEffect(() => {
        if (activeStep > maxStepSoFar) {
            setMaxStepSoFar(activeStep);
        }
    }, [activeStep, maxStepSoFar]);

    React.useEffect(() => {
        setMaxStepSoFar(activeStep);
    }, [activeStep, checked]);

    const steps = getSteps();
    const subscribe = () => { };
    console.log(userData);
    useEffect(() => {
        console.log(userData);
    }, [userData]);
    if (!CheckForPermission(['usage.view'], permissions)) {
        return <div></div>
    }
    return (
        <Grid container justify="center" xs={12} className={classes.root}>
            <Grid item justify="center" xs={12}>
                <Paper className={classes.stepperContainer} elevation={0}>
                    <Grid className="padding">
                        <Stepper activeStep={activeStep} connector={<ColorlibConnector />} alternativeLabel>
                            {steps.map((label, i) => (
                                <Step key={label.label} className="hoverable">
                                    <StepLabel>
                                        <Typography>{label.label}</Typography>
                                        <Typography variant="subtitle2">{label.subHeading}</Typography>
                                    </StepLabel>
                                </Step>
                            ))}
                        </Stepper>
                        <Grid container justify="center" className={classes.itemContainer}>
                            <Grid item xs={11}>
                                {activeStep === steps.length ? null : (
                                    <div>
                                        {/* {getStepContent(activeStep)} */}
                                        {activeStep === 0 && (
                                            <div>
                                                <UserRegister
                                                    setActiveStep={setActiveStep}
                                                    setSelectedCountry={setSelectedCountry}
                                                    selectedCountry={selectedCountry}
                                                    userData={userData}
                                                    setUserData={setUserData}
                                                />
                                            </div>
                                        )}
                                        {activeStep === 1 ? (
                                            <div>
                                                <Plan
                                                    userData={userData}
                                                    setUserData={setUserData}
                                                    setActiveStep={setActiveStep}
                                                />
                                            </div>
                                        ) : null}
                                        {activeStep === 2 ? (
                                            <div>
                                                <Addons
                                                    userData={userData}
                                                    selectedAddons={selectedAddons}
                                                    setSelectedAddons={setSelectedAddons}
                                                    createdAddons={createdAddons}
                                                    setCreatedAddons={setCreatedAddons}
                                                    setUserData={setUserData}
                                                    setActiveStep={setActiveStep}
                                                    subscribe={subscribe}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Index;
