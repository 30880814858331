import AuthEndpoints from "../Endpoints/AuthEndpoints";
import SubscriptionEndpoints from "../Endpoints/SubscriptionEndpoints";
import AdminUserEndpoints from '../Endpoints/AdminUserEndpoints'
import UserEndpoints from "../Endpoints/UserEndpoints";
import AccountDetailsStatsEndpoint from "../Endpoints/AccountDetailsStatsEndpoint";
import BulkActionsEndpoints from "../Endpoints/BulkActionsEndpoints";

const endpointMapper = {
    auth: AuthEndpoints,
    subscription: SubscriptionEndpoints,
    adminUser: AdminUserEndpoints,
    user: UserEndpoints,
    accountDetails: AccountDetailsStatsEndpoint,
    bulkActions: BulkActionsEndpoints
};
export default endpointMapper;
